import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { getQuizzes } from '../../api/quizzes.api';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}) => {
  return {
    async pageReady() {
      const { location } = controllerConfig.wixCodeApi;
      const queryPath = new URLSearchParams(location.query).toString();

      const result = await flowAPI.httpClient.request(getQuizzes());
      const items = result.data.quizzes?.map((q) => ({
        ...q,
        link: `${location.baseUrl}/quiz/${q.id}${
          queryPath ? `?${queryPath}` : ''
        }`,
      }));

      controllerConfig.setProps({ items });
    },
  };
};

export default createController;
